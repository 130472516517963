import React, { useState, useEffect } from 'react';
import thumbsUp from '../../../assets/images/Hand/thumbs-up.png';
import thumbsDown from '../../../assets/images/Hand/thumbs-down.png';
import sad from '../../../assets/images/Smiley/emoji-sad.svg';
import quite from '../../../assets/images/Smiley/emoji-quite.svg';
import smile from '../../../assets/images/Smiley/emoji.svg';

const StartRating = ({ questionsData, customerName, getQuestionsData, setCurrentRating, cryptoData, currentRating, isKTM = false }) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 450);

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth >= 450);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onRatingClick = (index) => {
        const selectedElement = document.getElementById('questionsMainContainer');
        const selectedElementDimensionFromTop = selectedElement && selectedElement.getBoundingClientRect().top;
        setTimeout(() => {
            window.scrollBy(0, selectedElementDimensionFromTop - 65, 'smooth');
        }, 550);
        setCurrentRating(index);
    };

    const getQuestionAsPerKey = () => {
        if (questionsData?.result && questionsData.result && questionsData.result.NPSFeedbackQuestions.length) {
            const ratingLevelQuestion = questionsData.result.NPSFeedbackQuestions.find(obj => obj.FrontEndCode === 'Rating')?.DisplayName;
            return ratingLevelQuestion;
        }
    };

    const ratingQuestion = getQuestionAsPerKey() || '';
    const sizeAsPerDetailsText = ratingQuestion.length > 140 ? 40 : 0;
    let totalSize = window.screen.height - (isDesktop ? (680 + sizeAsPerDetailsText) :
        (520 + 80 + (sizeAsPerDetailsText ? 20 : 0)));
    totalSize = isKTM ? totalSize + 20 : totalSize;
    const languageFields = `${cryptoData?.parentSchemaName.toLowerCase()}LanguageMaster`;

    // Define styles based on screen size
    const containerStyle = {
        marginBottom: isKTM ? 160 : 130,
        padding: isDesktop ? '30px' : '10px',
    };

    const customerNameStyle = {
        fontSize: isDesktop ? '20px' : '16px',
    };

    const detailsStyle = {
        fontSize: ratingQuestion.length > (isDesktop ? 140 : 150) ? (isDesktop ? '14px' : '13px') : (isDesktop ? '16px' : '14px'),
    };

    const likeUnlineTextStyle = {
        fontSize: isDesktop ? '18px' : '14px',
    };

    const rateBoxStyle = {
        width: isDesktop ? '20px' : '26px',
        // height: isDesktop ? '20px' : '20px',
        margin: isDesktop ? '0px 3px' : '0px 2px',
        backgroundColor:'#ddd',
        // display: isDesktop ? "" : 'none'
    };

    const languageStyle = {
        fontSize: isDesktop ? '18px' : '14px',
    };

    return (
        <div id='starRating' style={containerStyle} className='mainContainerRating'>
            <div className='customerName' style={customerNameStyle}>
                {questionsData && questionsData?.result && questionsData.result.WelcomeMsg}
            </div>
            <div className='customerName' style={customerNameStyle}>
                {customerName || ''}
            </div>
            <div className='details' id='detailsId' style={detailsStyle}>
                {ratingQuestion}
            </div>
            <div style={{ display: 'flex', marginTop: '60px' }}>
                <div>
                    <span className='likeUnlineText' style={likeUnlineTextStyle}>
                        {questionsData && questionsData?.result && questionsData.result.NotLikely || 'Not at all likely'}
                    </span>
                    <img className='thumbsDown' src={thumbsDown} alt="Thumbs Down" />
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <span className='likeUnlineText' style={likeUnlineTextStyle}>
                        {questionsData && questionsData?.result && questionsData.result.ExtremelyLikely || 'Extremely likely'}
                    </span>
                    <img className='thumbsDown' style={{ marginLeft: 'auto' }} src={thumbsUp} alt="Thumbs Up" />
                </div>
            </div>
            <div style={{ marginTop: '10px', marginBottom: '20px',display:'flex',justifyContent:'center', }} className='ratingContainer'>
                {[...Array(11)].map((item, index) => (
                    <div
                        onClick={() => onRatingClick(index)}
                        className='rateBox'
                        style={{
                            ...rateBoxStyle,
                            backgroundColor: currentRating === index
                                ? '#b2a7a7'
                                : '#ddd',
                            color: currentRating === index ? '#FFF' : 'inherit',
                            
                        }}
                        key={index}
                    >
                          {index < 7 ? <img className='sad' src={sad} alt="Sad Emoji" /> :
                          index > 6 && index < 9 ?
                             <img className='quite' src={quite} alt="Quite Emoji" /> :
                             <img className='smile' src={smile} alt="Smile Emoji" />}

                             <span style={{marginLeft:'1px'}}>{index}</span>
                        
                    </div>
                ))}
            </div>
           
            <div className='language' id='languageContainer' style={{ ...languageStyle, marginTop: `${totalSize}px` }}>
                {
                    questionsData && questionsData[languageFields] && questionsData[languageFields].map((obj, index) =>
                        <span
                            className='each-language'
                            key={index + obj.LegacyId}
                            onClick={() => getQuestionsData(obj.LegacyId)} >
                            {obj.Name}
                        </span>)
                }
            </div>
        </div>
    );
};

export default StartRating;
