
function getBaseAPI() {
  return process.env.NODE_ENV === "production" ?
    `https://api-${window.location.host.includes("chetak") ? "balub.excellonconnect.com" : window.location.host}/v1.0/`
    //`https://api-${window.location.host}/v1.0/` //"https://api-traya-staging2.azurewebsites.net/v1.0/"
     : "https://api-dev-balpb.excellonconnect.com/v1.0/";
    // : "https://api-dev-balpb.excellonconnect.com/v1.0/";
  // "http://api-dev-balib.excellonconnect.com/v1.0/";  
  //:"http://api-dev-balib.excellonconnect.com/v1.0/"
  // : "http://localhost:24020/v1.0/"; //http://api-dev-balib.excellonconnect.com/v1.0/
}

export class Config {
  constructor() {
    this.baseApiUrl = getBaseAPI();
    this.schemaDataSourceApiUrl = this.baseApiUrl + "Schema/";
    this.schemaApiUrl = this.baseApiUrl + "Schema/";
    this.schemaLocalizedApiUrl = this.baseApiUrl + "schemaviewlocalized/";
    this.formApiUrl = this.baseApiUrl + "formschema";
    this.schemaViewApiUrl = this.baseApiUrl + "schemaview/";
    this.documentApiUrl = this.baseApiUrl + "Document/";
    this.identityApiUrl = this.baseApiUrl + "identity/";
    this.whoAmIApiUrl = this.baseApiUrl + "whoami/";
    this.documentViewApiUrl = this.baseApiUrl + "searchdocumentview/";
    this.attachmentApiUrl = this.baseApiUrl + "attachment/";
    this.cdnattachmentApiUrl = this.baseApiUrl + "cdnattachment/";
    this.resourceApiUrl = this.baseApiUrl + "resource/";
    this.cndresourceApiUrl = this.baseApiUrl + "cdnresource/";
    this.customAction = this.baseApiUrl + "documentaction/";
    this.npscustomAction = "https://api-bal-ecom.excellonconnect.com/v1.0/dataaction/";

    //--UAT---
    //this.npscustomAction ="https://api-connect-uat.excellonconnect.com/v1.0/dataaction/"    																  
    this.downloadViewData = this.baseApiUrl + "downloaddocumentview/";
    this.subscriptionApiUrl = this.baseApiUrl + "provision/subscription/";
    this.documentView = this.baseApiUrl + "documentView/";
    this.subscriptionName = process.env.NODE_ENV === "production" ? "" : "BALPB"; //"BALUB"//"BALMC";// "BALIB";
    this.instrumentationKey = "3859b902-b5ea-490a-8522-db2319bf61d0"
    this.googleMapKey = "AIzaSyAuqZ4hU1vUqRjT6LQjZfH1TaTk4EmxOXY";
    this.callCenterApiUrl = "https://signalr.excelloncloud.com/ContactCenter"; //"http://192.168.0.11:8080/ContactCenter";
    this.designerApiUrl = "https://ktmdesigner.excelloncloud.com/?SchemaId=";
    this.triumphdesignerApiUrl = "http://triumphdesigner.excelloncloud.com/?SchemaId=";
    this.hvdesignerApiUrl = "https://tsthvdesigner.excelloncloud.com/?SchemaId=";

    this.maxDocUploadLimit = 25;
  }
}